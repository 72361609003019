import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,EditarUrl} from '../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios'
export default class MayordomoZona
{
    asignacionId:number =0;
    usuarioId:string = "";
    userName:string ="";
    haciendaId:Array<number> =[];
    haciendaName:string ="";
    mayordomoId:string ="";
    haciendaIdAsignada:number =0;
    ordenInterna:string="";
}

async function ObtenerAsignacionAll()
{
    return await Obtener<MayordomoZona>('mayordomo/ObtenerAsignacionAll',mainAxios);
}

async function ObtenerAsignacionDetalle(usuarioId:string)
{
    return await Obtener<MayordomoZona>(`mayordomo/ObtenerAsignacionUsuario/${usuarioId}`,mainAxios);
}

 async function  GuardarAsignacion(asignacion:MayordomoZona):Promise<ResponseGenerico>
{
    return await Save<MayordomoZona>("mayordomo/AddMayoromo",asignacion,mainAxios);
}


async function  EditarAsignacion(asignacionId:number):Promise<ResponseGenerico>
{
    return await EditarUrl(`mayordomo/DeleteAsignacion/${asignacionId}`,mainAxios);
}

export
{
    MayordomoZona,
    ObtenerAsignacionAll,
    ObtenerAsignacionDetalle,
    GuardarAsignacion,
    EditarAsignacion

}
