


















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
export default class extends Vue
{
    @Prop({type:Array,required:true})
    headers!:Array<any>;

    @Prop({type:Array,required:true})
    items!:Array<any>;

    //variables
    search:string="";


    UpdateTitle(estado:boolean):string
    {
        let texto:string ="";
        if(estado == true)
        {
            texto = "ACTIVO";
        }
        else
        {
            texto ="INACTIVO";
        }
        return texto;
    }
    UpdateColor(estado:boolean):string
    {
        let color:string ="";
        if(estado == true)
        {
            color = "primary";
        }
        else
        {
            color ="secondary";
        }
        return color;
    }

    get UpdateItems()
    {
        return this.items;
    }
}
