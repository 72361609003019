import HaciendaItemLote from "./HaciendaItemLote";
import ResponseGenerico from "../ResponseGenerico";
import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseCustom from "../ResponseCustom";
import {mainAxios,axiosKtb} from '@/plugins/axios'

export default class HaciendaItem
{
    haciendaId?:number;
    haciendaItemId?:number;
    descripcion?:string;
    hacienda:string;
    estado:boolean;
    ordenInterna?:string;
    hectarea?:number;
    anioCultivo?:number;
    lotes:Array<HaciendaItemLote> =[];
    haciendaLote:Array<HaciendaItemLote> =[];


    constructor(_haciendaId?:number,_haciendaItemId?:number, _descripcion?:string,_ordenInterna?:string,_anioCultivo?:number)
    {
        this.haciendaId = _haciendaId;
        this.haciendaItemId = _haciendaItemId;
        this.descripcion = _descripcion;
        this.ordenInterna = _ordenInterna;
        this.anioCultivo = _anioCultivo;
        this.hacienda = "";
        this.estado = true;
    }

    static async GuardarHaciendaItem(haciendaItem:HaciendaItem): Promise<ResponseGenerico>
    {
        return Save<HaciendaItem>('haciendaItem/agregarhaciendaitem',haciendaItem,mainAxios);
    }

    static async EditarHaciendaItem(haciendaItem:HaciendaItem): Promise<ResponseGenerico>
    {
        return Editar<HaciendaItem>('haciendaItem/editarItem',haciendaItem,mainAxios);
    }

    static async EditarHaciendaItemEstado(haciendaItem:HaciendaItem): Promise<ResponseGenerico>
    {
        return Editar<HaciendaItem>('haciendaItem/editarEstado',haciendaItem,mainAxios);
    }

    static async ObtenerHaciendaItem(): Promise<ResponseCustom<HaciendaItem>>
    {
        return Obtener<HaciendaItem>('haciendaItem/obtener',mainAxios);
    }
    static async ObtenerHaciendaRecepction(plantaId:number,tipoId:number)
    {
        return Obtener<HaciendaItem>(`reporte/obtenerhaciendarecepcion/${plantaId}/${tipoId}`,axiosKtb);
    }
}
