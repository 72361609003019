







































































































import {Vue,Component} from 'vue-property-decorator';
import TablaGeneral from '@/components/General/TablaGeneral.vue';
import {MayordomoZona,ObtenerAsignacionAll,ObtenerAsignacionDetalle, GuardarAsignacion, EditarAsignacion} from '@/entidades/Plantacion/MayordomoZona';
import Usuario from '@/entidades/Maestro/Usuario';
import Usuario2 from '@/entidades/Maestro/Usuario2';
import {Mutation} from 'vuex-class'
import Loading from '@/entidades/Sistema/Loading'
import Alerta from '@/entidades/Sistema/Alerta'
import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';

@Component({
    components:{
        TablaGeneral
    }
})
export default class AsignacionMayordomo extends Vue
{
    //Propiedas
    @Mutation('mostrarLoading',{namespace:'sistemaModule'}) mostrarLoading!:Function;
	@Mutation('mostarAlert',{namespace:'sistemaModule'}) mostarAlert!:Function;

     header:{text:string,value:string}[] =[
            {text:"Hacienda",value:'haciendaName'},
            {text:'Usuario', value: 'userName'},
            {text:'Estado', value: 'estado'},
            {text:'Editar', value: 'actions'}
    ]

    listado:Array<MayordomoZona> =[];
    search:string ="";
    usuarioId:string="";
    haciendaId:Array<number>=[];
    modalAgregar=false;
    listadoUsuario:Array<Usuario2> =[];
    listadoHacienda:Array<HaciendaItem> =[];
    habilitarSelect:boolean = true;
    abrilModal()
    {
        this.modalAgregar = true;
        this.haciendaId = [];
        this.usuarioId = "";
        this.habilitarSelect = true;
    }
    editar(item:Array<MayordomoZona>)
    {
        console.log(item);
        
        this.usuarioId = item[0].usuarioId;
        item.forEach(t=>{
            this.haciendaId.push(t.haciendaIdAsignada)
        });
        this.habilitarSelect = false;
        this.modalAgregar = true;
    }
    async quitarAsignacion(item:MayordomoZona)
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Cargando informacion"));
            var response = await EditarAsignacion(item.asignacionId);
            if(response.isSuccess == true)
            {
                this.mostarAlert(new Alerta(response.isMessage,true,"danger",3000));
                this.ObtenerAsignacion();
                this.modalAgregar = false;
                this.usuarioId = "";
                this.haciendaId = [];
                this.habilitarSelect = true;
            }
            else
            {
                this.mostarAlert(new Alerta(response.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.mostrarLoading(new Loading(false,""));
        }
    }
    UpdateColor(estado:boolean):string
    {
        let color:string ="";
        if(estado == true)
        {
            color = "primary";
        }
        else
        {
            color ="secondary";
        }
        return color;
    }
    UpdateTitle(estado:boolean):string
    {
        let texto:string ="";
        if(estado == true)
        {
            texto = "ACTIVO";
        }
        else
        {
            texto ="INACTIVO";
        }
        return texto;
    }
    async ObtenerAsignacion()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            var response = await ObtenerAsignacionAll();
            console.log(response);
            if(response.isSuccess == true)
            {
                this.listado = response.isResult;
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }finally{
            this.mostrarLoading(new Loading(false,""));
        }
    }

    async ObtenerUsuarioRolName()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            var response = await Usuario.ObtenerRolName("Mayordomo",1);
            if(response.isSuccess == true)
            {
                this.listadoUsuario  = response.isResult;
            }
        }
        catch(error)
        {
             //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }finally{
            this.mostrarLoading(new Loading(false,""));
        }
    }
    async ObtenerHaciendaItem()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            var response = await HaciendaItem.ObtenerHaciendaItem();
            if(response.isSuccess == true)
            {
             
                this.listadoHacienda  = response.isResult;
                
            }
        }
        catch(error)
        {
             //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }finally{
            this.mostrarLoading(new Loading(false,""));
        }
    }

    async guardar()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            var mayordomo:MayordomoZona = new MayordomoZona();
            mayordomo.usuarioId = this.usuarioId;
            mayordomo.haciendaId = this.haciendaId;
            mayordomo.mayordomoId = this.usuarioId;
            var response = await GuardarAsignacion(mayordomo);
            if(response.isSuccess == true)
            {
                 this.mostarAlert(new Alerta(response.isMessage,true,"primary",2000));
                this.ObtenerAsignacion();
                this.modalAgregar = false;
                this.usuarioId = "";
                this.haciendaId = [];
                this.habilitarSelect = true;
                
            }
            else
            {
                 this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
            }
        }
        catch(error)
        {
             //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }
        finally
        {
            this.mostrarLoading(new Loading(false,""));
        }
    }

    get validarFormulario()
    {
        if(this.haciendaId.length <=0 || this.usuarioId =="")
        {
            return true;
        }
        return false;
    }
    mounted() {
        this.ObtenerAsignacion();
        this.ObtenerUsuarioRolName();
        this.ObtenerHaciendaItem();
    }
    
}
