var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-text-field',{staticStyle:{"width":"500px"},attrs:{"append-icon":"fa-search","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"col-12",attrs:{"headers":_vm.headers,"items":_vm.UpdateItems,"search":_vm.search},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":_vm.UpdateColor(item.estado)},on:{"click":function($event){return _vm.$emit('editarEstado',item)}}},[_vm._v(_vm._s(_vm.UpdateTitle(item.estado)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.$emit('editar',item)}}},[_vm._v("fa-edit")])]}},{key:"item.campo",fn:function(ref){
var item = ref.item;
return [_vm._t("campo",null,null,item)]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_vm._t("cantidad",null,null,item)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }